import { useRouter } from "next/router";
import { useEffect } from "react";
import Footer from "src/components/Footer";
import Header from "src/components/Header/Header";
import HomeView from "src/components/Home/HomeView";
import LayoutDocument from "src/layouts/LayoutDocument";

const Home = () => {
  const router = useRouter();
  const isFromLanding = router.query?.["landing"];

  useEffect(() => {
    if (isFromLanding === "dat-lich") {
      global.phonePopupRef.current.checkOpen();
    }
  }, [isFromLanding]);

  return (
    <LayoutDocument title="Autoshine hệ thống rửa xe tự động tiêu chuẩn Đức">
      <Header />
      <HomeView />
      <Footer />
    </LayoutDocument>
  );
};

export default Home;
