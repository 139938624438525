export function setCssTextStyle(el, style, value) {
  var result = el.style.cssText.match(
      new RegExp("(?:[;\\s]|^)(" + style.replace("-", "\\-") + "\\s*:(.*?)(;|$))"),
    ),
    idx;
  if (result) {
    idx = result.index + result[0].indexOf(result[1]);
    el.style.cssText =
      el.style.cssText.substring(0, idx) +
      style +
      ": " +
      value +
      ";" +
      el.style.cssText.substring(idx + result[1].length);
  } else {
    el.style.cssText += " " + style + ": " + value + ";";
  }
}
