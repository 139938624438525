import { useEffect } from "react";
import SlideBanner from "src/components/Home/SlideBanner";
import { setCssTextStyle } from "src/utils/setCssTextStyle";

const SectionsFullPage = [
  { id: 1, Section: SlideBanner },
  // { id: 2, Section: OutstandingService },
  // { id: 3, Section: IntroduceService },
  // { id: 4, Section: ServiceExperience },
  // { id: 2, Section: Service },
  // { id: 3, Section: CompareProducts },
  // { id: 4, Section: OurServiceFacility },
  // { id: 5, Section: IntroServiceFacility },
  // { id: 6, Section: GermanStandard },
  // { id: 7, Section: OurProducts },
  // { id: 8, Section: BookAnAppointment },
  // { id: 9, Section: Blog },
  // { id: 10, Section: Partner },
];
export default function HomeView() {
  useEffect(() => {
    const fpWatermarks = document.querySelectorAll(".fp-watermark");
    if (fpWatermarks.length > 0) {
      fpWatermarks.forEach((fpWatermark) => {
        setCssTextStyle(fpWatermark, "display", `none !important`);
      });
    }
  });
  return (
    <div>
      {SectionsFullPage.map(({ id, Section }) => (
        <div key={id} className="section">
          <Section />
        </div>
      ))}
    </div>
  );
}
