/* eslint-disable @next/next/no-img-element */
import { Carousel } from "antd";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import BackgroundImage from "src/assets/images/background.png";
import useWindowSize from "src/hooks/useWindowSize";
// import { FaLongArrowAltRight } from "react-icons/fa";
import { TbTriangleInverted } from "react-icons/tb";
import { FaLongArrowAltRight } from "react-icons/fa";

const contentStyle = {
  height: "100vh",
  maxHeight: "100vh",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: `url(${BackgroundImage.src})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const arrowStyle = {
  border: "2px solid white",
  borderRadius: "50%",
  width: "45px",
  height: "45px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  top: "-15px",
};

export const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "70px", zIndex: 10 }}
      onClick={onClick}
    >
      <div style={{ ...arrowStyle }}>
        <AiOutlineRight color="white" fontSize={16} />
      </div>
    </div>
  );
};

export const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "50px", zIndex: 10 }}
      onClick={onClick}
    >
      <div style={{ ...arrowStyle }}>
        <AiOutlineLeft color="white" fontSize={16} />
      </div>
    </div>
  );
};

const settings = {
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

export default function SlideBanner() {
  const { width } = useWindowSize();
  const settingCarousel = width >= 768 ? settings : {};

  const handleBooking = () => {
    global.phonePopupRef.current.checkOpen();
  };
  const SlideItem = () => {
    return (
      <div>
        <div style={contentStyle}>
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-mb-[65px]">
              <h3 className="tw-text-[24px] tw-leading-[24px] tw-text-white tw-mb-[20px] tw-uppercase">
                Cách nhanh hơn để có
              </h3>
              <h1 className="tw-font-medium tw-text-[24px] tw-leading-[24px] md:tw-text-[65px] md:tw-leading-[65px]  tw-text-white tw-mb-[32px]">
                {" "}
                Một chiếc xe sạch hơn{" "}
              </h1>
              <h3 className="tw-text-[22px] tw-leading-[24px]  tw-text-white">
                Đơn giản hơn. Thông minh hơn - Autoshine
              </h3>
            </div>

            <button
              className="tw-h-[55px] tw-max-w-[280px] tw-w-[280px] tw-flex tw-items-center tw-justify-center tw-leading-[24px] tw-text-[20px] tw-uppercase tw-border-solid tw-border-[1px] tw-border-white tw-text-white tw-bg-button tw-rounded-full"
              onClick={handleBooking}
            >
              Đặt lịch ngay{" "}
              <FaLongArrowAltRight
                className="tw-ml-[10px] tw-relative tw-top-[2px]"
                fontSize={26}
              />
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="tw-carousel tw-relative tw-overflow-x-hidden">
      <Carousel
        arrows
        {...settingCarousel}
        dots={width < 768 ? { className: "!tw-mx-[unset]" } : false}
        autoplay
      >
        <SlideItem />
        <SlideItem />
        <SlideItem />
      </Carousel>
      <div className="tw-absolute tw-bottom-[30px] tw-left-1/2 ">
        <a href="#ID_Home_Service">
          <TbTriangleInverted
            className="tw-cursor-pointer"
            color="white"
            fontSize={36}
            style={{ transform: "translateX(-50%)" }}
          />
        </a>
      </div>
    </div>
  );
}
